import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"
import { getEBooksCoverUrl } from "utils/rsc-util"

const LinkMap = {
  Planlama: "ad8a5c5b9684",
  Analiz: "ad8a5c5b9684",
  Tasarım: "ad8a5c5b9684",
  Gerçekleştirme: "ad8a5c5b9684",
  Bakım: "ad8a5c5b9684",

  "Code&Fix": "ad8a5c5b9684",
  Waterfall: "ad8a5c5b9684",
  "V-Model": "ad8a5c5b9684",
  Evolutionary: "ad8a5c5b9684",
  Prototyping: "ad8a5c5b9684",
  Spiral: "ad8a5c5b9684",
  Incremental: "ad8a5c5b9684",
  UML: "ad8a5c5b9684",
  "Agile(XP,Kanban, Scrum)": "ad8a5c5b9684",

  "İş Paketleri": "b33d9c02b717",
  "Alt Kırılımlar": "b33d9c02b717",
  "Kaç Kişi, Yetenekler ve Roller": "b33d9c02b717",
  "Project Planning": "b33d9c02b717",

  "Yazılım Geliştirme Yöntemim": "9a0aac25f471",

  "İş ve Hayat Dengesi": "e9f3604f7ba9",
  "Work/Life Balance": "e9f3604f7ba9",

  "Ustalık-Çıraklık": "221c21ce6478",

  "FDD(Feature Driven Development)": "5bcdf6223c5a",
  "TDD(Test Driven Development)": "5bcdf6223c5a",
  "BDD(Behaviour Driven Development)": "5bcdf6223c5a",
  "ATDD(Acceptance Test Driven Development)": "5bcdf6223c5a",
  "MDD(Model Driven Development)": "5bcdf6223c5a",
  "DDD(Domain Driven Development)": "5bcdf6223c5a",
}

const eBooks = [
  {
    title: "Yazılım Geliştirme Pratikleri",
    url: "https://learnreactui.dev/contents/yazilim-gelistirme-pratikleri",
    img: getEBooksCoverUrl("076_yazilim_gelistirme_pratikleri"),
  },
]

const storiesGroup = [
  {
    title: "Yazılım Geliştirme Pratikleri",
    storyPart: [
      {
        title: "MVP (Minimum Viable Product) Nedir?",
        postId: "308253337e4f",
      },
      {
        title: "Örnek Bir MindMap Çalışması",
        postId: "77cab29df8bb",
      },
      {
        title: "Sketch, Wireframe, Mockup, Prototype",
        postId: "394338279e0f",
      },
      {
        title: "S.O.L.I.D Prensipleri",
        postId: "4991542a6b8d",
      },
      {
        title: "TDD(Test) vs BDD(Behaviour)",
        postId: "30e225c29c14",
      },
      {
        title: "Reactive Manifesto",
        postId: "113218b88ffd",
      },
      {
        title: "12 Factor App Uygun Geliştirme",
        postId: "61645e68f114",
      },
      {
        title: "JIRA’daki Scrum Board’una Bakış",
        postId: "58baeb72f973",
      },
      {
        title: "Pomodoro Tekniği (Zaman Yönetimi)",
        postId: "bfc97456cb2e",
      },
      {
        title: "Dogfooding",
        postId: "df0d26d4ba3",
      },
      {
        title: "Horizontal / Vertical Slicing",
        postId: "91c8a559af32",
      },
      {
        title: "Vibe Coding",
        postId: "d8d733d10652",
      },
    ],
  },
  {
    title: "Mimariyi Deneyim",
    storyPart: [
      {
        title: "Neden Sürece İhtiyaç Var",
        postId: "ad8a5c5b9684",
      },
      {
        title: "Proje Planı Nasıl Hazırlanır",
        postId: "b33d9c02b717",
      },
      {
        title: "Yazılım Geliştirme Yöntemim",
        postId: "9a0aac25f471",
      },
      {
        title: "İş ve Hayat Dengesi",
        postId: "e9f3604f7ba9",
      },
      {
        title: "Ustalık-Çıraklık İlişkisi",
        postId: "221c21ce6478",
      },
      {
        title: "{x} Driven Development",
        postId: "5bcdf6223c5a",
      },
      {
        title: "Startup'da Mühenislik",
        postId: "7288afd5bf24",
      },
    ],
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "Mimari Deneyim",
  path: "mimari-deneyim",
}

const ArchExperiencePage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      eBooks={eBooks}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default ArchExperiencePage
